import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
/*import Helmet from 'react-helmet'*/
import './header.css'

const Header = ({ siteTitle }) => (
  <div className="Header">
    <div className="HeaderSocial">
      <Link href="mailto:makefriends@broadleyspeaking.design"><img src={require('../images/email.svg')} width="20px" /></Link>
      <Link to="https://twitter.com/BroadleySpoken" target="_blank"><img src={require('../images/twitter.svg')} width="20px" /></Link>
      <Link to="https://www.linkedin.com/in/dean-broadley/" target="_blank"><img src={require('../images/linkedIn.svg')} width="20px" /></Link>
      </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
